.tos-ctr {
    position: fixed;
    background: white;
    z-index: 91000;
    width: 95%;
    max-width: 400px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 6px;
}

.tos-ctr h2 {
    text-align: center;
}

.tos-ctr p {
    width: 80%;
    margin: auto;
    margin-bottom: 20px;
    text-align: center;
    font-size: 14px;
}

.tos-scroll {
    overflow: scroll;
    height: 300px;
    width: 100%;
    margin: auto;
    margin-bottom: 20px;
}

.tos-scroll p {
    width: 90%;
    font-size: 12px;
    text-align: left;
    margin: 20px auto;
}

.tos-scroll .checkbox-ctr {
    margin-top: 0px;
}

.tos-scroll .checkbox {
    margin: 0px 10pxw;
}

.tos-scroll .checkbox {
    display: inline-block;
    position: relative;
    bottom: 60px;
}

.tos-scroll .checkbox-ctr p {
    width: 75%;
    display: inline-block;
    font-size: 10px;
}

.tos-btn-ctr {
    position: relative;
    width: 100%;
}

.tos-view-btn {
    position: relative;
    display: block;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 20px;
    width: 90%;
    text-align: center;
    border: none;
    border-radius: 6px;
    background: #008a00;
    color: white;
    padding: 6px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    font-size: 16px;
}

.tos-view-btn:hover {
    cursor: pointer;
    background: #00aa00;
}

.tos-view-btn:disabled {
    background: #eee;
    box-shadow: none;
    color: #aaa;
    border: 1px solid #aaa;
}

.tos-view-btn:disabled:hover {
    cursor: default;
    box-shadow: none;
    color: #aaa;
    border: 1px solid #aaa;
}

.tos-scroll .checkbox-ctr {
    margin-top: 0px;
}

.tos-scroll .checkbox {
    margin-right: 10px;
    margin-left: 10px;
}

.tos-scroll .checkbox {
    display: inline-block;
    position: relative;
    bottom: 30px;
}

.tos-scroll .checkbox-ctr p {
    width: 78%;
    display: inline-block;
    font-size: 10px;
}