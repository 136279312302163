.cart-alert {
    position: fixed;
    top: -100px;
    color: white;
    width: 100%;
    margin: auto;
    z-index: 1000000;
    animation-name: alert;
    animation-duration: 3.5s;
}

@keyframes alert {
    0%      {top: -100px}
    8%     {top: 60px}    
    92%     {top: 60px}    
    100%     {top: -100px}    
}

.cart-alert p {
    width: 95%;
    font-size: 14px;
    background: #333;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    margin: auto;
    padding: 12px 5px;
    text-align: center;
    border-radius: 6px;
}