html, body {
  font-family: "Leelawadee UI", "Segoe UI", "Roboto", "Arial", sans-serif;
  background: #000;
}

.app-ctr * {
  font-family: "Leelawadee UI", "Segoe UI", "Roboto", "Arial", sans-serif;
}

.app-ctr {
  width: 100%;
  height: 100%;
}

.logo-ctr {
  position: relative;
  margin: 60px auto 40px;
  width: 60%;
  max-width: 300px;
}

.logo-small {
  margin: 25px auto;
  max-width: 150px;
  transition: 300ms;
  opacity: 0;
}

.logo-ctr img {
  width: 100%;
}

.buttons-ctr {
  position: relative;
  text-align: center;
  margin-top: 100px;
}

.btn-ctr {
  position: relative;
  margin: 20px auto;
  width: 90%;
}

.menu-btn {
  width: 100%;
  max-width: 600px;
  height: 80px;
  font-size: 16px;
  letter-spacing: 5px;
  background: black;
  color: white;
  border: 2px solid white;
  border-radius: 6px;
}

.menu-btn:hover {
  background: #222;
  cursor: pointer;
}

.menu-btn:disabled {
  opacity: 0;
  pointer-events: none;
}

.cart-ctr {
  display: block;
  position: fixed;
  top: 0px;
  left: 0px;
  border-radius: 100px;
  z-index: 30000;
  padding: 8px 15px;
}

.cart-ctr h3 {
  font-family: 'Courier New', 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande';
  font-size: 14px;
  letter-spacing: 2px;
  color: white;
  margin: 0px;
  display: inline-block;
  position: relative;
  top: -10px;
  left: 5px;
}

/* .cart-ctr img {
  position: relative;
  top: 3px;
  left: 1px;
} */



.socials-ctr {
  position: fixed;
  width: 100%;
  height: 30px;
  bottom: 12%;
  text-align: center;
}

.social-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: relative;
  margin: 0px 20px;
  opacity: 0.6;
  transition: 200ms;
}

.social-icon img {
  width: 100%;
}

.social-icon:hover {
  cursor: pointer;
  opacity: 1;
  transition: 200ms;
}

@media (min-width: 600px) {
  .socials-ctr {
    height: 50px;
    bottom: 15%;
  }
  
  .social-icon {
    width: 50px;
    height: 50px;
    position: relative;
    margin: 0px 20px;
    opacity: 0.6;
    transition: 200ms;
  }
  
  .social-icon img {
    width: 100%;
  }
  
  .social-icon:hover {
    cursor: pointer;
    opacity: 1;
    transition: 200ms;
  }
}


.cart-ctr img {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
}

.cart-ctr:hover {
  cursor: pointer;
}

.cart-counter {
  position: fixed;
  height: 20px;
  top: 5px;
  right: 8px;
}

.cart-counter:disabled {
  opacity: 0;
}

.cart-counter h6 {
  border: 2px solid black;
  background: #e40000;
  text-shadow: 0px 0px 2px black;
  height: 30px;
  width: 30px;
  text-align: center;
  border-radius: 500px;
  color: white;
  font-size: 20px;
}

.cart-disabled {
  display: none;
  pointer-events: none;
}

.hidden {
  opacity: 0;
}

.bg-overlay {
  border-radius: 10px;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9000;
}

.cancel-ctr, .privacy-cancel-ctr {
  position: fixed;
  background: black;
  top: 0px;
  right: 0px;
  padding: 5px 15px;
  z-index: 21000;
  width: 35px;
}

.privacy-cancel-ctr {
  background: white;
}

.cancel-ctr:hover, .privacy-cancel-ctr:hover {
  cursor: pointer;
}

.privacy-cancel-ctr img, .cancel-ctr img {
  position: relative;
  top: 2px;
  width: 100%;
}

.privacy-cancel-ctr img, .cancel-ctr img {
  cursor: pointer;
}

.privacy-policy-btn {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.privacy-policy-btn p {
  color: white;
  opacity: 0.7;
}

.privacy-policy-btn p:hover {
  cursor: pointer;
  text-decoration: underline;
  opacity: 1;
}

.product-list {
  position: fixed;
  top: 50px;
  width: 100%;
  left: 50%;
  transform: translate(-50%, 0%);
  height: 100%;
  background: white;
  z-index: 11000;
}

.product-list h2 {
  text-align: center;
  font-size: 24px;
}

.product-list-enter {
  animation-name: enter;
  animation-duration: 0.3s;
}

@keyframes enter {
  0%    {top: 900px}
  100%  {top: 50px}
}

.cards-ctr {
  text-align: center;
  position: relative;
  display: block;
  height: 90%;
  overflow: scroll;
  margin: auto;
  margin-top: 8px;
  border-radius: 10px;
  width: 100%;
  max-width: 800px;
}

@media (min-height: 500px) {

}

.cards-ctr::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.cards-ctr, .cart-items {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.product-card {
  position: relative;
  display: inline-block;
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  width: 95%;
  max-width: 360px;
  height: 560px;
  margin: 5px;
}

.card-exit-btn {
  width: 85%;
  padding: 8px;
  border: none;
  background: #000;
  color: white;
  border-radius: 6px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
}

.card-exit-btn:hover {
  cursor: pointer;
  background: #222;
}

.product-card p {
  font-size: 12px;
  text-align: center;
}

.card-name p, .card-price p {
  font-size: 18px;
}

.card-price p {
  color: #51AE00;
}

.card-nic p {
  margin: 0px auto;
}

.product-card select {
  border: 1px solid #555;
  border-radius: 4px;
  padding: 4px;
  color: black;
  background: #f2f2f2;
  font-size: 14px;
}

select {
  border: 1px solid #555;
  border-radius: 4px;
  padding: 4px;
  color: black;
  background: #f2f2f2;
}

.flavor-select {
  width: 140px;
  margin: 0px 5px;
  margin-right: 15px;
}

.cart-flavor-select {
  width: 100px;
  margin: 0px 5px;
  margin-right: 15px;
}

.view-info select {
  font-size: 14px;
}

.qty-select {
  width: 40px;
  margin: 0px 5px;
}

.cart-qty-select {
  width: 35px;
  margin: 0px 5px;
  margin-left: 23px;
}

.product-card input {
  width: 20px;
  margin-left: 5px;
}

.card-info {
  width: 95%;
  position: relative;
  margin: 20px auto;
}

/* Chrome, Safari, Edge, Opera */
.product-card input::-webkit-outer-spin-button,
.product-card input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.product-card input[type=number] {
  -moz-appearance: textfield;
}

.card-btn-ctr {
  position: relative;
  width: 90%;
  margin: 25px auto;
}

.add-to-cart {
  background: #51AE00;
}

.view-product {
  background: black;
}

.card-btn {
  border: none;
  border-radius: 6px;
  padding: 8px;
  color: white;
  position: relative;
  margin: auto 2.5%;
  width: 45%;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
}

.card-btn:hover {
  cursor: pointer;
}

.add-to-cart:disabled {
  background: grey;
  color: black;
  opacity: 0.3;
  pointer-events: none;
}

.card-img img {
  width: 100%;
  position: relative;
  display: block;
  margin: auto;
  max-width: 300px;
}

.view-img img {
  width: 100%;
  position: relative;
  display: block;
  margin: auto;
  border-radius: 10px 10px 0px 0px;
}

.show-cart-ctr {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 15000;
  border-radius: 0px;
  animation-name: cart-enter;
  animation-duration: 0.2s;
}

@keyframes cart-enter {
  0%    {top: 1000px}
  100%  {top: 0px}
}

@keyframes x-enter {
  0%    {top: 1000px}
  100%  {top: 2px}
}

.cancel-black {
  background: white;
  animation-name: x-enter;
  animation-duration: 0.2s;
  top: 2px;
}

.cart-img img {
  width: 100%;
  position: relative;
  display: block;
  max-width: 60px;
}



.image-ctr {
  margin: 10px auto 10px;
}

.view-img {
  max-width: 180px;
}

.view-info-ctr {
  margin-top: 20px;
}

.view-info-ctr p {
  font-size: 18px;
  text-align: center;
  margin: 10px auto;
}


.view-info {
  margin: 20px auto;
}

.view-info p {
  font-size: 14px;
}
.product-view-info p {
  font-size: 14px;
}

.view-desc {
  position: relative;
  margin: auto;
  max-width: 800px;
}

.view-desc p {
  text-align: left;
  margin: 0px;
  margin-left: 20px;
  font-size: 14px;
}

.view-info-ctr .card-btn-ctr {
  margin-top: 20px;
  max-width: 720px;
}

@media (min-width: 400px) {
  .view-desc p {
    margin-left: 30px;
  }
}

@media (min-height: 600px) {
  .view-img {
    max-width: 250px;
  }
}

@media (min-height: 800px) {
  .view-img {
    max-width: 300px;
  }

  .view-info select {
    font-size: 16px;
  }

  .view-info-ctr p {
    font-size: 24px;
  }

  .view-info p {
    font-size: 16px;
  }

  .product-view-info p {
    font-size: 16px;
  }

  .view-desc p {
    font-size: 18px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .view-desc {
    margin-bottom: 30px;
  }
}

@media (min-height: 850px) {
  .view-desc p {
    margin: 15px auto 15px 30px;
  }
}

@media (min-height: 900px) {
  .view-desc {
    margin-top: 40px;
  }

  .view-info-ctr .card-btn-ctr {
    /* position: fixed;
    bottom: 100px;
    left: 5%; */
    margin-top: 100px;
  }

  .view-info-ctr .card-btn {
    padding: 10px;
    font-size: 18px;
  }
}

@media (min-width: 600px) {
  .view-desc p {
    margin-left: 50px;
  }
}

.product-list h2 {
  font-size: 18px;
  color: white;
  position: fixed;
  width: 100%;
  top: -52px;
  z-index: 20000;
}

@media (min-height: 500px) {
  .card-img {
    width: 250px;
  }
}

.cart-items {
  overflow: scroll;
  height: 67%;
}

.cut-price {
  color: red;
  opacity: 0.5;
  text-decoration-line: line-through;
}

.final-price {
  color: #51AE00;
}

.cart-item {
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  margin: 6px auto;
  max-width: 450px;
}

.cart-img {
  display: inline-block;
  margin: 20px 5px;
}

.cart-info-ctr {
  position: relative;
  display: inline-block;
  bottom: 10px;
  max-width: 200px;
}

.cart-info-ctr p {
  margin: 5px auto;
}

.cart-info-ctr .view-info {
  margin: 10px auto;
}

.cart-info-ctr .view-info p {
  font-size: 12px;
}
.cart-info-ctr .view-info select {
  font-size: 12px;
  padding: 3px;
  max-width: 140px;
}

.cart-price-ctr {
  position: relative;
  display: inline-block;
  left: 0px;
  bottom: 45px;
  text-align: center;
}

@media (min-width: 300px) {
  .cart-price-ctr {
    left: 10px;
  }
}
@media (min-width: 320px) {
  .cart-price-ctr {
    left: 20px;
  }
}
@media (min-width: 340px) {
  .cart-price-ctr {
    left: 40px;
  }
}
@media (min-width: 360px) {
  .cart-price-ctr {
    left: 60px;
  }
}
@media (min-width: 370px) {
  .cart-price-ctr {
    left: 10px;
  }
}
@media (min-width: 400px) {
  .cart-price-ctr {
    left: 30px;
  }
}
@media (min-width: 420px) {
  .cart-price-ctr {
    left: 50px;
  }
}
@media (min-width: 440px) {
  .cart-price-ctr {
    left: 70px;
  }
}

.cut-price {
  margin-bottom: 5px;
}

.final-price {
  font-size: 18px;
  margin: 0px auto;
}

.msg-ctr {
  position: relative;
  text-align: center;
  font-size: 12px;
  opacity: 0.5;
  bottom: 10px;
}

.msg-ctr p {
  font-size: 10px;
  margin: 0px;
}

.cart-total-ctr {
  position: fixed;
  width: 100%;
  background: black;
  height: 250px;
  bottom: 40px;
}

.cart-subtotal {
  position: relative;
  width: 100%;
  padding: 8px 0px;
  background: #e6e6e6;
  border-top: 1px solid #d2d2d2;
}

.cart-subtotal p {
  font-size: 12px;
  width: 30%;
  margin: 0px;
  display: inline-block;
}

.cart-total {
  text-align: center;
  padding: 10px 0px;
}

.cart-total p {
  width: 30%;
  margin: 0px;
  display: inline-block;
  font-size: 18px;
  color: white;
  font-weight: bold;
}

.checkout-btn {
  position: relative;
  width: 95%;
  padding: 8px;
  font-size: 18px;
  color: white;
  background: #51AE00;
  border: none;
  border-radius: 4px;
  text-align: center;
  margin: 18px auto;
}





.checkout {
  position: fixed;
  animation-name: enter;
  animation-duration: 0.3s;
  top: 50px;
  width: 100%;
  left: 50%;
  transform: translate(-50%, 0%);
  height: 100%;
  background: white;
  z-index: 49000;
}

.checkout h2 {
  background: black;
  text-align: center;
  font-size: 18px;
  color: white;
  position: fixed;
  width: 100%;
  top: -52px;
  z-index: 50000;
}

.cart-info-ctr p {
  font-size: 12px;
}

.view-info-small p {
  display: block;
}

.view-info p {
  display: inline-block;
}

.cart-msg {
  margin-top: 30px;
  margin-bottom: 140px;
  text-align: center;
  font-size: 12px;
  opacity: 0.6;
}

.view-info {
  max-width: 160px;
}

.product-view-info {
  max-width: 100%;
}

.cart-price-ctr .cut-price {
  font-size: 12px;
}

.cart-price-ctr .final-price {
  font-size: 14px;
}

.privacy-policy-ctr {
  position: fixed;
  background: white;
  top: 0px;
  left: 0px;
  z-index: 900000;
  width: 100%;
  height: 100%;
}

@media (min-width: 370px) {
  .view-info {
    max-width: 250px;
  }

  .product-view-info {
    max-width: 100%;
    margin: 20px 0px;
  }

  .cart-price-ctr .cut-price {
    font-size: 14px;
  }
  
  .cart-price-ctr .final-price {
    font-size: 16px;
  }

  .cart-price-ctr {
    bottom: 40px;
  }

  .cart-img img {
    width: 100%;
    position: relative;
    display: block;
    max-width: 60px;
  }

  .qty-select {
    width: 40px;
  }

  .cart-info-ctr p {
    font-size: 16px;
  }

  .cart-info-ctr {
    max-width: 250px;
    position: relative;
    display: inline-block;
    bottom: 40px;
  }

  .msg-ctr {
    bottom: 15px;
  }

  .msg-ctr p {
    font-size: 12px;
  }

  .view-info select {
    margin: 0px 10px;
    font-size: 14px;
  }
}

@media (min-width: 400px) {
  .cart-img img {
    width: 100%;
    position: relative;
    display: block;
    max-width: 65px;
  }

  .view-info {
    max-width: 300px;
  }

  .qty-select {
    width: 40px;
  }
}
