.counter-ctr {
    margin-top: 10px;
    margin-bottom: 25px;
}

.step-ctr {
    width: 33%;
    position: relative;
    display: inline-block;
    z-index: 110000;
}

.step-circle {
    position: relative;
    margin: auto;
    background: #51AA00;
    width: 50px;
    height: 50px;
    text-align: center;
    border-radius: 50px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
}

.step-disabled {
    background: #e6e6e6;
    box-shadow: none;
}

.step-circle h1 {
    position: relative;
    top: 45%;
    transform: translate(0%, -50%);
    color: white;
    font-size: 28px;
}

.step-slider {
    position: fixed;
    top: 49px;
    background: #51AA00;
    height: 10px;
    z-index: 100000;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    transition: 300ms;
}

.step-slider-grey {
    position: fixed;
    top: 49px;
    width: 100%;
    background: #e6e6e6;
    height: 10px;
}