.checkout {
    text-align: center;
}

.checkout-btn {
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0%);
    bottom: 100px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    font-size: 14px;
}

.checkout-exit-btn {
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0%);
    bottom: 80px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    font-size: 14px;
    width: 95%;
    padding: 8px;
    border: none;
    background: black;
    border-radius: 4px;
    color: white;
}

.checkout-exit-btn:hover {
    cursor: pointer;
    background: #222;
}

.checkout-form .checkout-btn {
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0%);
    bottom: 60px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    font-size: 14px;
}

.checkout-btn:hover {
    cursor: pointer;
}

.checkout-form {
    max-width: 450px;
    margin: auto;
}

.input-left {
    text-align: left;
    margin-left: 35px;
}

.checkout-form h1 {
    text-align: center;
    font-size: 16px;
    margin: 0px auto 20px;
}

.checkout-form p {
    width: 80%;
    max-width: 450px;
    margin: 20px auto;
    text-align: left;
    font-size: 12px;
    opacity: 0.7;
}

/* .checkout-form input {
    width: 50%;
    font-size: 16px;
    border: none;
    border-bottom: 1px solid #aaa;
    padding: 5px 2px;
    margin: 1.5%;
    border-radius: 0px;
} */

.checkout-btn:disabled {
    cursor: default;
    background: #f2f2f2;
    color: #c3c3c3;
    box-shadow: none;
}

.form-input {
    width: 50%;
    font-size: 12px;
    border: none;
    border-bottom: 1px solid #aaa;
    padding: 5px;
    margin: 1.5%;
    border-radius: 0px;
}

.invalid {
    border-bottom: 1px solid red;
}

.invalid:focus {
    box-shadow: 0px 0px 0px 4px red;
}

.checkbox-ctr {
    margin-top: 0px;
}

.checkbox {
    margin-right: 20px;
}

.checkbox {
    display: inline-block;
    position: relative;
    bottom: 60px;
}

.checkbox-ctr p {
    width: 65%;
    display: inline-block;
    font-size: 10px;
}

.spinner-ctr * {
    position: relative;
    width: 100%;
    margin-top: 0px;
}

.checkout-message {
    margin-top: 50px;
}

.checkout-message p {
    font-size: 18px;
    text-align: center;
}

/* Chrome, Safari, Edge, Opera */
.checkout-form input::-webkit-outer-spin-button,
.checkout-form input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.checkout-form input[type=number] {
  -moz-appearance: textfield;
}

.checkout-form .loader-text {
    position: relative;
    top: 250px;
    font-size: 24px;
    color: #51AE00;
}

@media (min-width: 350px) {
    .checkout-form .loader-text {
        top: 300px;
    }
}

@media (min-width: 400px) {
    .checkbox {
        bottom: 40px;
    }
}

@media (min-height: 650px) {
    .checkout-form .checkout-btn {
        bottom: 25%;
    }

    .checkout-form .checkout-exit-btn {
        bottom: 22%;
    }
}
@media (min-height: 800px) {
    .checkout-form .checkout-btn {
        bottom: 30%;
    }

    .checkout-form .checkout-exit-btn {
        bottom: 27%;
    }
}